var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "DebtorDataDilution" },
    [
      _c("transition-group", { attrs: { name: "Transition__fade" } }, [
        _vm.requestsProgress.dilution
          ? _c("div", { key: "dilution", staticClass: "column" }, [
              _c("label", { staticClass: "fs-18 mt-22 mb-20" }, [
                _vm._v("\n        Dilution:\n        "),
                _c("span", { staticClass: "fc-light fs-16" }, [
                  _vm._v(_vm._s(_vm.avgDilutionRate) + "%")
                ])
              ]),
              _c("canvas", { ref: "dilution-chart" })
            ])
          : _vm._e(),
        _vm.requestsProgress.nonpayments
          ? _c("div", { key: "nonpayments", staticClass: "column" })
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }